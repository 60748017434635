@import "@/assets/styles/master";











































































































































































































































































































.g30s-input {
	.v-input__prepend-outer {
		font-size: 1.75rem;
		margin-top: 15px;
	}
	.v-input__append-inner {
		align-self: center !important;
		margin-top: 0 !important;
	}
	.v-input__control {
		margin-left: 10px;
	}
}
