@import "@/assets/styles/master";

































































































.body {
	cursor: pointer;
	color: $primary;
	border: 1px solid $stone;
	border-radius: 50px;
	background-color: transparent;
	transition: all 0.3s ease-in-out;

	&:hover {
		background-color: $primary;
		text-decoration: none;

		p, .icon {
			color: $white;
		}
	}

	@include md {
		border: 1.5px solid $stone;
		padding: 20px 28px;
	}
}

.body--plain {
	padding: 0;
	border: none;
	border-radius: 0;

	&:hover {
		background-color: transparent;
	}

	.icon {
		transition: all 0.3s ease-in-out;
	}
}

.body--primary {
	background-color: $primary;

	p {
		color: $white;
	}

	&:hover {
		background-color: lighten($primary, 5%);
	}
}

.body--secondary {
	color: $secondary;

	&:hover {
		p, .icon {
			color: $primary;
		}
	}
}

.icon {
	&--text {
		font-size: 1.5rem;

		@include md {
			font-size: 2rem;
		}
	}
}

.text {
	font-size: 1.125rem;
	line-height: 25px;
	font-weight: bold;

	@include md {
		font-size: 1.4rem;
		line-height: 25px;
	}
}
