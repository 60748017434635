@import 'spacing';
@import 'flexbox';

.primary {
	background-color: $primary;

	&--text {
		color: $primary;
	}
}

.secondary {
	background-color: $secondary;

	&--text {
		color: $secondary;
	}
}

.white {
	background-color: $white;

	&--text {
		color: $white;
	}
}
