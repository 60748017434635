@import "@/assets/styles/master";








































.anchor {
	width: 100vw;
}

.navbar {
	transition: box-shadow 0.3s ease-in;
	padding: 0;
}

.navbar--active {
	z-index: 2;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $pearl;
	margin: 0;
	box-sizing: border-box;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.10);
}
