@for $i from 0 through 16 {
  $spacers: map-merge($spacers, (
    $i: (
      x: $spacer-x * $i,
      y: $spacer-y * $i
    )
  ))
}

.app {
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $spacers {
      $length-x:   map-get($lengths, x);
      $length-y:   map-get($lengths, y);

      .#{$abbrev}a-#{$size} { #{$prop}:        $length-y $length-x; } // a = All sides
      .#{$abbrev}t-#{$size} { #{$prop}-top:    $length-y; }
      .#{$abbrev}r-#{$size} { #{$prop}-right:  $length-x; }
      .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y; }
      .#{$abbrev}l-#{$size} { #{$prop}-left:   $length-x; }

      // Axes
      .#{$abbrev}x-#{$size} {
        #{$prop}-right:  $length-x;
        #{$prop}-left:   $length-x;
      }
      .#{$abbrev}y-#{$size} {
        #{$prop}-top:    $length-y;
        #{$prop}-bottom: $length-y;
      }

      // negatives
      .#{$abbrev}a-n#{$size} { #{$prop}:        (-$length-y) (-$length-x); } // a = All sides
      .#{$abbrev}r-n#{$size} { #{$prop}-right:  (-$length-x); }
      .#{$abbrev}t-n#{$size} { #{$prop}-top:    (-$length-y); }
      .#{$abbrev}b-n#{$size} { #{$prop}-bottom: (-$length-y); }
      .#{$abbrev}l-n#{$size} { #{$prop}-left:   (-$length-x); }

      // Axes
      .#{$abbrev}x-n#{$size} {
        #{$prop}-right:  (-$length-x);
        #{$prop}-left:   (-$length-x);
      }
      .#{$abbrev}y-n#{$size} {
        #{$prop}-top:    (-$length-y);
        #{$prop}-bottom: (-$length-y);
      }
    }
  }
}