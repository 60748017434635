@import url('https://fonts.googleapis.com/css2?family=Chivo&display=swap');

body {
	font-family: $font-family;
	font-size: $base-font;
}

p, li {
	margin: 0;
	font-size: 1rem;
	line-height: 24px;
}

.text-bold {
	font-weight: bold;
}

.text-center {
	text-align: center;
}

h1 {
	color: $primary;
}

h1, .title-1 {
	font-size: 3.125rem;
	font-weight: bold;
	margin: 0;

	@include md {
		font-size: 5rem;
		line-height: 70px;
	}

	@include lg {
		font-size: 6.25rem;
		line-height: 90px;
	}
}

h2 {	
	color: $primary;
}

h2, .title-2 {
	font-size: 2.1875rem;
	font-weight: bold;
	line-height: 40px;
	margin: 0;

	@include md {
		font-size: 2.8125rem;
		line-height: 50px;
	}

	@include lg {
		font-size: 3.75rem;
		line-height: 65px;
	}
}

h3, .title-3 {
	font-size: 2rem;
	line-height: 1;
	font-weight: bold;
	color: $primary;
	margin: 0 0 20px 0;
}

.subtitle-1 {
	font-size: 1.5625rem;
	font-weight: bold;
	line-height: 35px;

	@include md {
		font-size: 1.875rem;
		line-height: 45px;
	}
}

p, .body-1 {
	font-size: 1.25rem;
	line-height: 30px;
	color: $secondary;

	@include lg {
		font-size: 1.5625rem;
		line-height: 35px;
	}
}