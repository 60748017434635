@import "@/assets/styles/master";































































































































.logo {
	@include lg {
		width: 250px;
	}
}

.spacer-1 {
	@include md {
		height: 150px;
		margin-top: -75px;
	}
}

.spacer-2 {
	height: 150px;
	margin-top: -75px;
}

.heading {
	transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
	width: 100%;
	min-height: 0px;
	background-image: url('~@/assets/img/get30sec-background.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding: 20px 0 70px;
	min-height: 75vh;

	@include md {
		min-height: 87vh;
		padding: 30px 0 120px;
		background-position: center center;
	}

	@include lg {
		background-position: top;
		padding-bottom: 125px;
	}
}

.section {
	width: 100%;
	margin: 16px 0 0;

	@include md {
		align-items: center;
		gap: 32px;
		margin: 16px 20px 0;
	}

	@include lg {
		max-width: 970px !important;
	}

	.steps {
		margin-top: 32px;

		@include md {
			align-items: center;
			gap: 64px;
			margin-bottom: 96px;
		}
	}

	.section-margin {
		// change how this margin is handled
		margin: 0 20px;

		@include sm {
			margin: 0 90px;
		}
	}

	.app-buttons {
		max-width: 100%;
		gap: 16px;
		padding: 16px 20px;
		box-sizing: border-box;

		.button {
			height: 50px;
			// min-width: 175px;

			@include md {
				height: 75px;
			}

			&--apple {
				svg {
					margin-top: -5px;
						font-size: 1.75rem;

					@include md {
						font-size: 2.5rem;
					}
				}
			}
		}

		@include md {
			padding: 20px;
		}
	}

	.navbar {
		height: 90px;

		@include md {
			height: 120px;
		}
	}
}
