@import "@/assets/styles/master";



























.spacer {
	width: 100%;
	height: 100px;
	margin-top: -50px;
}

.line {
	height: 100%;
	width: 2px;
	background-color: $white;
}
