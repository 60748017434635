@import './normalize';
@import './typography';

* {
	box-sizing: border-box;
}

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	min-height: 100vh;
	box-sizing: border-box;
	color: $white;
	background-color: $background-color;
	overflow: hidden;
}

a {
	color: $secondary;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

ul {
	padding-left: 40px;
	margin: 16px 0;
}

ol {
	margin-left: 20px;
}
