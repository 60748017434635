@import "@/assets/styles/master";

































































.footer {
	padding-bottom: 5.25rem;
}

.copyright {
	text-align: center;
	margin-right: 0.30rem;
	width: 100%;

	@include md {
		width: auto;
		display: inline-block;
	}
}

.spacer {
	&--mobile {
		display: none;

		@include md {
			display: inline-block;
		}
	}
}

.social-profiles {
	margin: 50px 0;

	@include md {
		margin: 100px 0;
	}
}

.link {
	display: inline-block;
}
