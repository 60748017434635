// colors
$white: #FFF;
$purple: #540087;  
$pearl: #efe5cf;
$stone: #a39880;


// variables
$primary: $purple;
$secondary: $stone;
$background-color: $pearl;