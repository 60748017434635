.d-flex {
	display: flex;
}
.inline-flex {
	display: inline-flex;
}

.flex-row {
	flex-direction: row;
}
.flex-row-reverse {
	flex-direction: row-reverse;
}
.flex-column {
	flex-direction: column;
}
.flex-column-reverse {
	flex-direction: column-reverse;
}

.justify-center {
	justify-content: center;
}
.justify-start {
	justify-content: flex-start;
}
.justify-end {
	justify-content: flex-end;
}
.justify-space-between {
	justify-content: space-between;
}
.justify-space-around {
	justify-content: space-around;
}

.align-center {
	align-items: center;
}
.align-start {
	align-content: flex-start;
}
.align-end {
	align-content: flex-end;
}
.align-space-between {
	align-content: space-between;
}
.align-space-around {
	align-content: space-around;
}

.flex-grow {
	flex-grow: 1;
}

.flex-wrap {
	flex-wrap: wrap;
}

.self-align-start {
	align-self: flex-start;
}

.self-align-end {
	align-self: flex-end;
}

@for $i from 0 through 16 {
  .flex-gap-#{$i} { gap: $spacer * $i; }
}
