@import "@/assets/styles/master";


































































.section, .content, .navbar-home {
	max-width: 100%;

	@include lg {
		max-width: 970px !important;
	}
}

.markdown {
	p {
		font-size: 1rem;
		line-height: 24px;
		margin-bottom: 12px;
	}

	a {
		color: $purple;
	}
}

.button {
	padding: 12px 20px;
}

.navbar {
	height: 78px;

	@include md {
		height: 84px;
	}
}
